/* eslint-disable prefer-template */
/* eslint-disable no-unused-vars */
/* eslint-disable react/no-unescaped-entities */
import React, { useState, useEffect, useContext, useMemo } from 'react';
import BPStyled from './style';
import Header from '../Header';
import IncludedCheck from '../IncludedCheck';
import WalletButton from '../WalletButton';
import TierOneBadge from '../../assets/tier-1-badge.png';
import TierTwoBadge from '../../assets/tier-2-badge.png';
import useCountdown from '../../utils/useCountdown';
import tierList from '../../utils/tier-list';
import { ETHPriceContext } from '../../context/ETHPriceContext';
import { CsvCMSDataContext } from '../../context/CsvCMSDataContext';

function MintCountDown() {
  const targetDate = new Date('2023-03-01T21:30:00+05:30');
  const [days, hours, minutes] = useCountdown(targetDate);
  return (
    <div>
      {`Starting in 
        ${days > 0 ? days + ' Days ' : ''}
        ${hours > 0 ? hours + ' Hours ' : ''}
        ${minutes > 0 ? minutes + ' Minutes ' : ''}
      `}
    </div>
  );
}

function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

function BenefitsAndPricing() {
  const [tab, setTab] = useState(2);
  const [tierOnePrice, setTierOnePrice] = useState();
  const [tierTwoPrice, setTierTwoPrice] = useState();

  const { ethUSDPrice, loading } = useContext(ETHPriceContext);
  const { tierOnePerks, tierTwoPerks } = useContext(CsvCMSDataContext);

  useEffect(() => {
    if (!loading) {
      const priceOne = numberWithCommas((ethUSDPrice * tierList[0].ethPrice).toFixed(0));
      const priceTwo = numberWithCommas((ethUSDPrice * tierList[1].ethPrice).toFixed(0));

      setTierOnePrice(priceOne);
      setTierTwoPrice(priceTwo);
    }
  }, [loading, ethUSDPrice]);

  const Tier1Benefits = useMemo(() => (
    <>
      {tierOnePerks.map((perk, index) => (
        <div className='item' key={`tier-1-perk-list-benefits-section-${index}`}>
          <div className={`item-icon ${perk['Icon'] === 'Cross' ? 'exclude' : ''}`}>
            {perk['Icon'] === 'Green Check' ?
              <IncludedCheck /> : perk['Icon'] === 'Yellow Check' ?
                <IncludedCheck partialPerk /> : 'x'}
          </div>
          <div className='item-text'>
            {perk['Highlighted'] !== '' ?
              <span className='bold-text'>{`${perk['Highlighted']} `}</span> : null}
            {perk['Perk Text'] !== '' ? `${perk['Perk Text']} ` : null}
            {perk['Perk Note'] !== '' ?
              <span className='italic-light'>{`${perk['Perk Note']} `}</span> : null}
            {(perk['Link Text'] !== '' && perk['Link URL'] !== '') ?
              <a href={perk['Link URL']} target='_blank' className='orange-text'
                 rel='noreferrer'>{perk['Link Text']}</a> : null}
          </div>
        </div>
      ))}
    </>
  ), [tierOnePerks]);

  const Tier2Benefits = useMemo(() => (
    <>
      {tierTwoPerks.map((perk, index) => (
        <div className='item' key={`tier-1-perk-list-benefits-section-${index}`}>
          <div className={`item-icon ${perk['Icon'] === 'Cross' ? 'exclude' : ''}`}>
            {perk['Icon'] === 'Green Check' ?
              <IncludedCheck /> : perk['Icon'] === 'Yellow Check' ?
                <IncludedCheck partialPerk /> : 'x'}
          </div>
          <div className='item-text'>
            {perk['Highlighted'] !== '' ?
              <span className='bold-text'>{`${perk['Highlighted']} `}</span> : null}
            {perk['Perk Text'] !== '' ? `${perk['Perk Text']} ` : null}
            {perk['Perk Note'] !== '' ?
              <span className='italic-light'>{`${perk['Perk Note']} `}</span> : null}
            {(perk['Link Text'] !== '' && perk['Link URL'] !== '') ?
              <a href={perk['Link URL']} target='_blank' className='orange-text'
                 rel='noreferrer'>{perk['Link Text']}</a> : null}
          </div>
        </div>
      ))}
    </>
  ), [tierTwoPerks]);

  return (
    <BPStyled id='Benifits-Pricing'>
      <div className='container'>
        <Header
          headingOne='The Nitty Gritty'
          headingTwo='Benefits & Pricing'
          rowGap='25px'
        />
        <div className='sub-heading'>
          <div className='heading ff-NL fw-700'>
            Overview benefits: What you get
          </div>
          <div className='text ff-CG fw-400'>
            With The Prime Barrel NFT{' '}
            <span className='ff-BO'>lifetime membership</span>
            {'  '}, you'll enjoy an exclusive set of benefits that will please
            any whiskey enthusiast. There are three membership tiers, allowing
            you greater flexibility to pick the Tier that fits your lifestyle
            best. Because this is a lifetime membership, there are{' '}
            <span className='ff-BO'>no renewal fees</span> - you pay once and
            are a member as long as you hold your NFT. You may even pass it on
            to a member of your family, turning it into a generational affair.
            And we will be there for every step of it - we've been operating for
            over a decade and not looking to stop any time soon.
          </div>
        </div>
        <div className='benefits'>
          <div className='tier tier-1'>
            <img className='badge' src={TierOneBadge} alt='' />

            <div className='header-section'>
              <h2>Tier 1</h2>
              <h3>Single Barrel Savant</h3>
              <div className='sub-title'>
                Availability: 193
              </div>
            </div>

            <div className='body-section'>
              <div className='price-wrapper'>
                <h4>Price</h4>
                <div className='price-data-wrapper'>
                  <p className='price'>${tierOnePrice}</p>
                  <div className='line' />
                </div>
              </div>

              <div className='expect-items'>
                <div className='expect-title'>
                  <h4>Here's what to expect:</h4>
                  <div className='line' />
                </div>

                {Tier1Benefits}
              </div>

              <div className='wallet-btn'>
                <WalletButton
                  action='CONNECT_AND_WHITELIST'
                  defaultText='Select'
                  currentTier='tier-one'
                />
              </div>
            </div>
          </div>
          <div className='tier tier-2'>
            <img className='badge' src={TierTwoBadge} alt='' />

            <div className='header-section'>
              <h2>Tier 2</h2>
              <h3>Single Barrel Advocate</h3>
              <div className='sub-title'>
                Availability: 1,800
              </div>
            </div>

            <div className='body-section'>
              <div className='price-wrapper'>
                <h4>Price</h4>
                <div className='price-data-wrapper'>
                  <p className='price'>${tierTwoPrice}</p>
                  <div className='line' />
                </div>
              </div>

              <div className='expect-items'>
                <div className='expect-title'>
                  <h4>Here's what to expect:</h4>
                  <div className='line' />
                </div>

                {Tier2Benefits}
              </div>

              <div className='wallet-btn'>
                <WalletButton
                  action='CONNECT_AND_WHITELIST'
                  defaultText='Select'
                  currentTier='tier-two'
                />
              </div>
            </div>
          </div>
        </div>

        <div className='tabs'>
          <div className='indicaters'>
            <div className={`one ${tab === 1 ? 'selected-indicater' : ''}`} />
            <div className={`two ${tab === 2 ? 'selected-indicater' : ''}`} />
          </div>
          <div className='tabs-wrapper'>
            <button
              type='button'
              onClick={() => setTab(1)}
              className={`tab ${tab === 1 ? 'selected-tab' : ''}`}
            >
              Tier 1
            </button>
            <button
              type='button'
              onClick={() => setTab(2)}
              className={`tab ${tab === 2 ? 'selected-tab' : ''}`}
            >
              Tier 2
            </button>
          </div>
        </div>

        <div className='benefits-mobile'>
          {tab === 1 && (
            <div className='tier tier-1'>
              <img className='badge' src={TierOneBadge} alt='' />

              <div className='header-section'>
                <div className='title'>
                  <h2>Tier 1</h2>
                  <p className='desc'>Best Value</p>
                </div>
                <h3>Single Barrel Savant</h3>
                <div className='sub-title'>
                  Availability: 193
                </div>
              </div>

              <div className='body-section'>
                <div className='body-top-wrapper'>
                  <div className='price-wrapper'>
                    <h4>Price</h4>
                    <div className='price-data-wrapper'>
                      <p className='price'>${tierOnePrice}</p>
                    </div>
                  </div>
                </div>

                <div className='expect-items'>
                  {Tier1Benefits}
                </div>

                <div className='wallet-btn'>
                  <WalletButton
                    action='CONNECT_AND_WHITELIST'
                    defaultText='Select'
                    currentTier='tier-one'
                  />
                </div>
              </div>
            </div>
          )}
          {tab === 2 && (
            <div className='tier tier-2'>
              <img className='badge' src={TierTwoBadge} alt='' />

              <div className='header-section'>
                <div className='title'>
                  <h2>Tier 2</h2>
                </div>
                <h3>Single Barrel Advocate</h3>
                <div className='sub-title'>
                  Availability: 1,800
                </div>
              </div>

              <div className='body-section'>
                <div className='body-top-wrapper'>
                  <div className='price-wrapper'>
                    <h4>Price</h4>
                    <div className='price-data-wrapper'>
                      <p className='price'>${tierTwoPrice}</p>
                    </div>
                  </div>
                </div>

                <div className='expect-items'>
                  {Tier2Benefits}
                </div>

                <div className='wallet-btn'>
                  <WalletButton
                    action='CONNECT_AND_WHITELIST'
                    defaultText='Select'
                    currentTier='tier-two'
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </BPStyled>
  );
}

export default BenefitsAndPricing;
