import React from 'react';
import TRStyled from './style';
import Header from '../Header';
import YoutubePlayer from '../YoutubePlayer';

function ToolsAndResources() {
  return (
    <TRStyled id='Tools-Resources'>
      <div className='container'>
        <Header
          headingOne='Learn More'
          headingTwo='Tools & Resources'
          rowGap='25px'
          headerWidth='848px'
        />
        <div className='desc ff-CG fw-400'>
          Below, you can find helpful tutorials and videos on how to set up and
          use digital wallets. <br /> <br />
          If you are new to the space, a digital wallet is a software program
          that stores your cryptocurrency and NFTs. You DO NOT need to have any
          crypto in it if it’s “not your thing.” However, you need it to store
          and allow us to validate your digital membership. <br /> <br />
          Setting up a digital wallet is easy and only takes a few minutes.
          These videos will walk you through the process step-by-step and show
          you how to connect your wallet to our platform.
        </div>
        <div className='videos'>
          <div className='iframe-section'>
            <div className='video-title'>
              HOW TO CREATE A COINBASE DIGITAL WALLET IN A WEB BROWSER:
            </div>
            <YoutubePlayer videoId='zI-DlR_xtIY' className='iframe' />
          </div>
          <div className='iframe-section'>
            <div className='video-title'>
              HOW TO CREATE A METAMASK DIGITAL WALLET IN WEB BROWSER:
            </div>
            <YoutubePlayer videoId='xLCAWwMVhBk' className='iframe' />
          </div>
          <div className='iframe-section'>
            <div className='video-title'>
              HOW TO CREATE A COINBASE DIGITAL WALLET ON A MOBILE DEVICE:
            </div>
            <YoutubePlayer videoId='FEplYbOxodg' className='iframe' />
          </div>
          <div className='iframe-section'>
            <div className='video-title'>
              HOW TO CREATE A METAMASK DIGITAL WALLET ON A MOBILE DEVICE:
            </div>
            <YoutubePlayer videoId='DuetxUUOZq8' className='iframe' />
          </div>
          <div className='iframe-section'>
            <div className='video-title'>
              HOW TO CONNECT A DIGITAL WALLET MOBILE APP:
            </div>
            <YoutubePlayer videoId='O0F8l3cojps' className='iframe' />
          </div>
          <div className='iframe-section'>
            <div className='video-title'>
              HOW TO CONNECT A DIGITAL WALLET WEB BROWSER:{' '}
            </div>
            <YoutubePlayer videoId='wJemnu2H01Q' className='iframe' />
          </div>
          <div className='iframe-section'>
            <div className='video-title'>
              HOW TO JOIN THE WAITLIST FOR OUR WHISKEY”S CLUB DIGITAL MEMBERSHIP
              ON A BROWSER:
            </div>
            <YoutubePlayer videoId='wizIOozs3DM' className='iframe' />
          </div>
          <div className='iframe-section'>
            <div className='video-title'>
              HOW TO JOIN A WAITLIST FOR OUR WHISKEY”S CLUB DIGITAL MEMBERSHIP
              ON A MOBILE APP:
            </div>
            <YoutubePlayer videoId='SpX_tx1taMw' className='iframe' />
          </div>
          <div className='iframe-section'>
            <div className='video-title'>
              HOW TO BUY THE PRIME BARREL DIGITAL MEMBERSHIP:
            </div>
            <YoutubePlayer videoId='Mq_VwvOK_G4' className='iframe' />
          </div>
        </div>
      </div>
    </TRStyled>
  );
}

export default ToolsAndResources;
