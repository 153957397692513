import React from 'react';
import AutoCarouselFGStyled from './style';
import Header from '../Header';
import WalletButton from '../WalletButton';
import CarouselContent from '../CarouselContent';
import MiniCarousel from '../MiniCarousel';

function AutoCarouselFG({ slide }) {
  return (
    <AutoCarouselFGStyled>
      {slide.headingOne && slide.headingTwo && (
        <Header
          id={slide.id}
          headingOne={slide.headingOne}
          headingTwo={slide.headingTwo}
          headingThree={slide.headingThree}
          rowGap={slide.rowGap}
          headerWidth={slide.headerWidth}
        />
      )}

      {!slide.subCarousel ? (
        <CarouselContent slide={slide} />
      ) : (
        <MiniCarousel subCarousel={slide.subCarousel} />
      )}

      {slide.showWalletButton && (
        <div className='btn'>
          <WalletButton defaultText='Join Now' />
        </div>
      )}

      {slide.info && (
      <div className='info'>
        { slide.info }
      </div>
      )}
    </AutoCarouselFGStyled>
  );
}

export default AutoCarouselFG;
