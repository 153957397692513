import stickerSagamore from './sticker-sagamore.png';
import stickerFoundNorth from './sticker-foundnorth.png';
import stickerCopperCask from './sticker-coppercask.png';
import stickerLaws from './sticker-laws.png';
import stickerRareCharacter from './sticker-rarecharacter.png';
import stickerIronclad from './sticker-ironclad.png';
import stickerFourRoses from './sticker-fourroses.png';
import stickerBullRun from './sticker-bullrun.png';
import stickerDadsHat from './sticker-dadshat.png';

export const partnersFirstLine = [
  {
    sticker: stickerFoundNorth,
    name: 'Found North',
    firstImageWidth: '84px',
    firstImageHeight: '30px',
    secondImageWidth: '210px',
    secondImageHeight: '76px',
    marginLeft: '-11px',
  },
  {
    sticker: stickerCopperCask,
    name: 'Copper & Cask',
    firstImageWidth: '84px',
    firstImageHeight: '30px',
    secondImageWidth: '246px',
    secondImageHeight: '103px',
  },
  {
    sticker: stickerSagamore,
    name: 'Sagamore',
    firstImageWidth: '55px',
    firstImageHeight: '55px',
    secondImageWidth: '160px',
    secondImageHeight: '160px',
  },
]

export const partnersSecondLine = [
  {
    sticker: stickerLaws,
    name: 'Laws',
    firstImageWidth: '55px',
    firstImageHeight: '55px',
    secondImageWidth: '160px',
    secondImageHeight: '160px',
  },
  {
    sticker: stickerRareCharacter,
    name: 'Rare Character',
    firstImageWidth: '68px',
    firstImageHeight: '35px',
    secondImageWidth: '245px',
    secondImageHeight: '125px',
  },
  {
    sticker: stickerFourRoses,
    name: 'Four Roses',
    firstImageWidth: '55px',
    firstImageHeight: '55px',
    secondImageWidth: '160px',
    secondImageHeight: '160px',
  },
]

export const partnersThirdLine = [
  {
    sticker: stickerBullRun,
    name: 'Bull Run',
    firstImageWidth: '75px',
    firstImageHeight: '30px',
    secondImageWidth: '245px',
    secondImageHeight: '98px',
  },
  {
    sticker: stickerDadsHat,
    name: 'Dad’s Hat',
    firstImageWidth: '75px',
    firstImageHeight: '30px',
    secondImageWidth: '245px',
    secondImageHeight: '98px',
  },
  {
    sticker: stickerIronclad,
    name: 'Dad\'s Hat',
    firstImageWidth: '55px',
    firstImageHeight: '55px',
    secondImageWidth: '160px',
    secondImageHeight: '160px',
  },
]