/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/jsx-no-useless-fragment */
import React, { useContext } from 'react';
import { Carousel } from 'react-responsive-carousel';
import { PartnershipsStyled, Outer } from './style';
import Header from '../Header';
import {partnersFirstLine, partnersSecondLine} from '../../assets/partnerStickers/partnersDesktop';
import {
  partnersFirstLine as partnersFirstLineMobile,
  partnersSecondLine as partnersSecondLineMobile,
  partnersThirdLine as partnersThirdLineMobile,
} from '../../assets/partnerStickers/partnersMobile';
import { CsvCMSDataContext } from '../../context/CsvCMSDataContext';
import useIsMobile from '../../hooks/useIsMobile';

function Partnerships() {
  const { barrelPickingPartners } = useContext(CsvCMSDataContext);
  const { isMobile } = useIsMobile()

  return (
    <PartnershipsStyled id='Brand-Partners'>
      <Outer>
        <Carousel
          showThumbs={false}
          showStatus={false}
          transitionTime={1000}
          preventMovementUntilSwipeScrollTolerance
          swipeScrollTolerance={15}
        >
          <div className='container'>
            <Header
              headingOne='Partnerships'
              headingTwo='Barrel Picking Partners'
              headerWidth='898px'
              headerHeight='240px'
              rowGap='25px'
            />
            <div className='content list'>
              <div className='list-container'
                   style={{ gridTemplateRows: `repeat(${Math.ceil(barrelPickingPartners.length / (isMobile ? 2 : 3))}, 1fr)` }}>
                {barrelPickingPartners.map((partner, index) => (
                  <div key={`barrel-picking-partners-section-${index}`}>{partner['Partner Name']}</div>))
                }
              </div>
              <div className='bottom-text'>& Many More</div>
            </div>
          </div>
          <div className='container'>
            <Header
              headingOne='Partnerships'
              headingTwo='Brand Partners For Exclusive Releases'
              headingThree='(and more are joining)'
              headerWidth='898px'
              rowGap='25px'
            />
            <div className='content stickers'>
              <div className='brand-partner-container'>
                <div className='partner-one'>
                  <div className='stickers'>
                    {partnersFirstLine.map((j) => (
                      <img
                        key={`sticker-${j.name}`}
                        src={j.sticker}
                        alt=''
                        style={{
                          width: j.secondImageWidth,
                          height: j.secondImageHeight,
                        }}
                      />
                    ))}
                  </div>
                  <div className='name-one'>
                    {partnersFirstLine.map((k) => (
                      <div key={k.name} style={{ width: k.secondImageWidth }}>{k.name}</div>
                    ))}
                  </div>
                </div>
                <div className='partner-two'>
                  <div className='stickers'>
                    {partnersSecondLine.map((l) => (
                      <img
                        key={`sticker-${l.name}`}
                        src={l.sticker}
                        alt=''
                        style={{
                          width: l.secondImageWidth,
                          height: l.secondImageHeight,
                        }}
                      />
                    ))}
                  </div>
                  <div className='name-two'>
                    {partnersSecondLine.map((m) => (
                      <React.Fragment key={m.name}>
                        {m.name === 'Ragtime' ? (
                          <div style={{ width: '253px', marginLeft: '-37px' }}>
                            New York Distilling Co.
                          </div>
                        ) : m.name === 'Ironclad' ? (
                          <div style={{ marginLeft: '-52px' }}>{m.name}</div>
                        ) : (
                          <div style={{ width: m.secondImageWidth }}>
                            {m.name}
                          </div>
                        )}
                      </React.Fragment>
                    ))}
                  </div>
                </div>
              </div>
              <div className='partners-mobile'>
                <div>
                  <div className='Array-div'>
                    {[
                      ...partnersFirstLineMobile,
                      ...partnersSecondLineMobile,
                      ...partnersThirdLineMobile,
                    ].map((j) => (
                      <div key={`mob-${j.name}`} className='logo-wrapper'>
                        <img
                          src={j.sticker}
                          alt=''
                          style={{
                            width: j.firstImageWidth,
                            height: j.firstImageHeight,
                          }}
                        />
                        <div style={{ fontSize: '11px', lineHeight: '14px' }}>
                          {j.name}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                <div className='bottom-text'>& Many More</div>
              </div>
            </div>
          </div>
        </Carousel>
      </Outer>
    </PartnershipsStyled>
  );
}

export default Partnerships;
